// src/services/request.js

export const request = (
  path,
  method,
  body,
  stringify = true,
  useApi = true,
  timeout = 15000
) => {
  if(!path || !method) {
    throw new Error('Both path and method are required for making a request');
  }

  let fetchPath = '';

  /* eslint-disable no-undef */
  // Determine the environment
  const env = process.env.NODE_ENV;
  const appPath = process.env.APP_PATH;
  const beUrl = process.env.BE_URL;
  const apiPath = process.env.API_PATH;
  /* eslint-enable no-undef */

  // Set the fetch path based on the environment
  if(env === 'development') {
    fetchPath = `${beUrl}${useApi ? apiPath : ''}${path}`;
  } else if(env === 'production') {
    const browserUrl = `${window.location.protocol}//${window.location.hostname}`;
    fetchPath = `${browserUrl}${appPath}${useApi ? apiPath : ''}${path}`;
  }

  const fetchOptions = {
    method,
    credentials: 'include',
    headers: {}
  };

  if(body) {
    fetchOptions.body = stringify ? JSON.stringify(body) : body;
    if(stringify) {
      fetchOptions.headers['Content-Type'] = 'application/json';
    }
  }

  const controller = new AbortController();
  fetchOptions.signal = controller.signal;

  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => {
      controller.abort();  // Actually cancels the request
      reject(new Error('Request timed out'));
    }, timeout);
  });

  const fetchPromise = fetch(fetchPath, fetchOptions)
    .then(async res => {
      const contentType = res.headers.get('Content-Type') || '';
      const isJson = contentType.includes('application/json');

      if(!res.ok) {
        const error = new Error('Request failed');
        error.response = isJson ? await res.json() : await res.text();
        throw error;
      }

      return isJson ? await res.json() : await res.text();
    });

  return Promise.race([fetchPromise, timeoutPromise]);
};

export const chatRequest = async (messages, onChunk) => {
  const fetchPath =
    process.env.NODE_ENV === 'development'
      ? `${process.env.BE_URL}${process.env.API_PATH}/assistant/chat/know-it-all`
      : `${window.location.protocol}//${window.location.hostname}${process.env.APP_PATH}${process.env.API_PATH}/assistant/chat/know-it-all`;

  const response = await fetch(fetchPath, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ messages }),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok.');
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  let buffer = '';
  let jsonBuffer = '';
  let citationCounter = 0;

  const processJsonObject = (rawString) => {
    try {
      // Remove any leading "data:" prefixes and split multiple JSON objects
      const jsonStrings = rawString
        .split(/(?<=})\s*(?={)/)  // Split between JSON objects
        .map(str => str.replace(/^data:\s*/, '').trim())
        .filter(str => str);  // Remove empty strings

      for (const jsonStr of jsonStrings) {
        try {
          const parsed = JSON.parse(jsonStr);
          
          switch (parsed.type) {
            case 'message_start':
              // Handle message start...
              break;

            case 'content_chunk': {
              if (parsed.delta?.content) {
                onChunk(parsed.delta.content);
              }
              break;
            }

            case 'citation': {
              citationCounter++;
              // Get all valid references
              const validRefs = (parsed.citation?.references || [])
                .filter(ref => ref.file?.name) // Filter for refs with a name (UUID)
                .map(ref => {
                  // Extract UUID from the filename
                  const uuid = ref.file.name.split('.')[0]; // Assuming format is "uuid.extension"
                  return uuid;
                })
                .filter(Boolean);

              if (validRefs.length > 0) {
                // Create numbered links for each reference
                const citationLinks = validRefs
                  .map((uuid, idx) => {
                    // We'll update the href later when we get the docType
                    return `<a data-uuid="${uuid}" href="#" class="citation-link">[${citationCounter}${validRefs.length > 1 ? String.fromCharCode(97 + idx) : ''}]</a>`;
                  })
                  .join(', ');
                
                // Wrap in superscript tags and add to content with external link indicator
                onChunk(`<sup>${citationLinks} <span style="font-size: 0.8em; color: #666;">↗</span></sup>`);
              }
              break;
            }

            case 'message_end':
              // Handle message end...
              break;
          }
        } catch (err) {
          console.error('Error processing JSON object:', err, jsonStr);
        }
      }
    } catch (err) {
      console.error('Error processing JSON objects:', err, rawString);
    }
  };

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    // Decode the current chunk and add it to our running buffer
    buffer += decoder.decode(value, { stream: true });

    // Split on newlines to handle one line at a time
    const lines = buffer.split('\n');
    // Keep the last line in buffer if it doesn't end with a newline
    buffer = lines.pop() || '';

    for (let line of lines) {
      // Clean up whitespace
      line = line.trim();
      if (!line) continue;

      // Sometimes we get repeated "data: " prefixes
      while (line.startsWith('data:')) {
        line = line.replace(/^data:\s?/, '');
      }

      if (!line) continue;

      // Add the current line to our JSON buffer
      jsonBuffer += line;

      // Try to parse the accumulated JSON buffer
      try {
        const result = JSON.parse(jsonBuffer);
        // If successful, process the object and reset the buffer
        processJsonObject(jsonBuffer);
        jsonBuffer = '';
      } catch (err) {
        // If it's not valid JSON yet, continue accumulating
        if (err instanceof SyntaxError) {
          continue;
        }
        // If it's some other error, log it and reset the buffer
        console.error('Error parsing JSON:', err);
        jsonBuffer = '';
      }
    }
  }

  // Process any remaining data in the buffer
  if (jsonBuffer) {
    try {
      processJsonObject(jsonBuffer);
    } catch (err) {
      console.error('Error processing final buffer:', err);
    }
  }
};
