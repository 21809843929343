import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getUser, getUserLoading } from '../../data/selectors/userAuthSelectors';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { chatRequest } from '../../services/request';
import styles from './chat.css';
import { FaArrowCircleUp } from 'react-icons/fa';
import { HiOutlineSparkles } from 'react-icons/hi2';
import { LuMessageCircleWarning } from 'react-icons/lu';
import MarkdownContent from './MarkdownContent';
import { getDocTypeByStudyUuid } from '../../services/studies';

export default function Chat() {
  const navigate = useNavigate();
  const userLoading = useSelector(getUserLoading);
  const user = useSelector(getUser);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if(!user.email) navigate('/login#chat');
  }, [user.email, navigate]);

  useEffect(() => {
    document.title = 'KNOW - AI-Assisted Discovery';
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSubmit = async(e) => {
    e.preventDefault();
    if(!inputMessage.trim()) return;

    const userMessage = {
      role: 'user',
      content: inputMessage.trim()
    };

    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');
    setIsLoading(true);
    setIsStreaming(true);

    try {
      // Initialize an empty assistant message
      const assistantMessage = {
        role: 'assistant',
        content: ''
      };
      
      setMessages(prev => [...prev, assistantMessage]);

      // Update the message content as chunks arrive
      await chatRequest([...messages, userMessage], (chunk) => {
        // `chunk` is already just the text fragment from parsed.delta.content
        setMessages((prev) => {
          const newMessages = [...prev];
          const lastMessage = newMessages[newMessages.length - 1];
          lastMessage.content += chunk;
          return newMessages;
        });
      });      
    } catch(error) {
      console.error('Error sending message:', error);
      // You might want to show an error message to the user here
    } finally {
      setIsLoading(false);
      setIsStreaming(false);
    }
  };

  const handleLinkClick = async (e) => {
    e.preventDefault();
    const element = e.target;
    
    // Only handle citation links
    if (!element.classList.contains('citation-link')) {
      return;
    }

    const uuid = element.getAttribute('data-uuid');
    if (!uuid) return;

    try {
      const { docType } = await getDocTypeByStudyUuid(uuid);
      if (!docType) {
        throw new Error('Could not determine document type');
      }

      // Construct the shared URL
      const sharedUrl = `https://knowintegrativeoncology.org/shared?uuid=${uuid}&docType=${docType}`;
      
      // Open in a new tab
      window.open(sharedUrl, '_blank');
    } catch (error) {
      console.error('Error fetching study details:', error);
      // Optionally show an error message to the user
    }
  };

  if(userLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={styles.chatContainer} role="main" aria-label="'KNOW-It-All'ai-assisted discovery">
      <h1>KNOW-It-All <span className={styles.chatHeading}><HiOutlineSparkles /></span></h1>
      
      <div className={styles.messagesContainer}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`${styles.message} ${
              message.role === 'user' ? styles.user : styles.assistant
            }`}
          >
            <MarkdownContent content={message.content} onLinkClick={handleLinkClick} />
            {isStreaming && index === messages.length - 1 && message.role === 'assistant' && (
              <span className={styles.loadingDots}></span>
            )}
          </div>
        ))}
        {isLoading && !isStreaming && (
          <div className={styles.loadingMessage}>
            <LoadingSpinner />
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={handleSubmit} className={styles.inputForm}>
        <textarea
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Prompt our AI assistant here..."
          className={styles.input}
          rows="2"
          disabled={isLoading}
          onKeyDown={(e) => {
            if(e.key === 'Enter' && !e.shiftKey) {
              handleSubmit(e);
            }
          }}
        />
        <button 
          type="submit" 
          className={styles.sendButton}
          disabled={isLoading || !inputMessage.trim()}
        >
          <FaArrowCircleUp style={{ fontSize: '2em' }} />
        </button>
      </form>
      <div className={styles.disclaimer}>
        <p>
          <span className={styles.attentionIcon} style={{ color: '#683266' }}><LuMessageCircleWarning /></span>
          <i>
          AI-Assisted Discovery is experimental and should not be relied upon exclusively.
            <br />
          Please review original summaries and source materials.
          </i>
        </p>
      </div>
    </div>
  );
}
