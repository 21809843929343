import React from 'react';
import PropTypes from 'prop-types';
import styles from './results.css';
import { FaFileAlt } from 'react-icons/fa';
const propTypes = {
  studyCounts: PropTypes.shape({
    fullCount: PropTypes.number,
    systematicCount: PropTypes.number,
    clinicalCount: PropTypes.number,
    observationalCount: PropTypes.number,
    caseCount: PropTypes.number,
    unknownCount: PropTypes.number,
    limitedCount: PropTypes.number
  }),
  supportLitCount: PropTypes.number,
  searchFlag: PropTypes.bool,
  onDownload: PropTypes.func.isRequired
};

export default function ResultSectionHeaderMkII({ studyCounts, supportLitCount, searchFlag, onDownload }) {
  const { fullCount, systematicCount, clinicalCount, observationalCount, caseCount, unknownCount, limitedCount } = studyCounts;
  const handleHashUpdate = hashValue => {
    window.location.hash = '';
    window.location.hash = hashValue;
  };

  const downloadSpan = <span 
    title="Download Citations in RIS Format" 
    className={styles.resultCount}
    onClick={onDownload}
    style={{ cursor: 'pointer' }}
    aria-label="download citations in RIS format"
  >
    <FaFileAlt size={14} />
  </span>;

  const resultsSummaryText = limitedCount == fullCount ? `Results (${searchFlag && fullCount + supportLitCount})` : `Results (${searchFlag && limitedCount} of ${fullCount + supportLitCount})`;

  const hasResults = fullCount > 0;

  return (
    <h2 className={styles.resultsHeader} aria-label="results information">
      <strong style={{ marginRight: '4px' }}>{resultsSummaryText}</strong>
      {!!systematicCount && <span title="Systematic Reviews/Meta Analyses" className={`${styles.resultCount} ${styles.sysBadge}`} onClick={() => handleHashUpdate('sysrev')} aria-label="systematic review results count">
        Systematic Reviews: {systematicCount}
      </span>}
      {!!clinicalCount && <span title="Clinical Trials" className={`${styles.resultCount} ${styles.clinBadge}`} onClick={() => handleHashUpdate('clintri')} aria-label="clinical trial results count">
        Clinical Trials: {clinicalCount}
      </span>}
      {!!observationalCount && <span title="Observational" className={`${styles.resultCount} ${styles.obsBadge}`} onClick={() => handleHashUpdate('obser')} aria-label="observational study results count">
        Observational Studies: {observationalCount}
      </span>}
      {!!caseCount && <span title="Case studyCounts" className={`${styles.resultCount} ${styles.caseBadge}`} onClick={() => handleHashUpdate('casrep')} aria-label="case report results counts">
        Case Reports: {caseCount}
      </span>}
      {!!unknownCount && <span title="Pending Summary" className={`${styles.resultCount} ${styles.pendBadge}`} onClick={() => handleHashUpdate('pensum')} aria-label='pending summary results counts'>
        Pending Summary: {unknownCount}
      </span>}
      {!!supportLitCount && <span title="Support Literature" className={`${styles.resultCount} ${styles.supBadge}`} onClick={() => handleHashUpdate('suplit')} aria-label="support literature results count">
        Support Literature: {supportLitCount}
      </span>}
      {/* {limitedCount < fullCount && <p>We recommend trying to make a more specific search</p>} */}
      {hasResults && downloadSpan}
    </h2>
  );
}

ResultSectionHeaderMkII.propTypes = propTypes;
