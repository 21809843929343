import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SummarizedResults from './SummarizedResults';
import SupportLitResults from './SupportLitResults';
// import Pagination from '../pagination/Pagination';
import styles from './results.css';
import LoadingSpinner from '../../loadingSpinner/LoadingSpinner';
import ResultSectionHeaderMkII from './ResultSectionHeaderMkII';
// import { useSelector } from 'react-redux';
// import { getSearchQuery } from '../../../data/selectors/searchSelectors';

const propTypes = {
  summarizedStudies: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.object),
    fullCount: PropTypes.number,
    observationalCount: PropTypes.number,
    clinicalCount: PropTypes.number,
    systematicCount: PropTypes.number,
    caseCount: PropTypes.number,
    unknownCount: PropTypes.number
  }).isRequired,
  filteredKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  supportLit: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.object),
    fullCount: PropTypes.number,
  }).isRequired,
  updateCallback: PropTypes.func.isRequired,
  masterSearchQuery: PropTypes.object.isRequired,
  // pageCallback: PropTypes.func.isRequired,
  searchOccuredFlag: PropTypes.bool.isRequired,
  // currentPage: PropTypes.number.isRequired,
  // setCurrentPage: PropTypes.func.isRequired,
  studiesLoading: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    newSearch: PropTypes.bool
  }).isRequired,
  onDownload: PropTypes.func.isRequired
};

export default function ResultsSection({ 
  summarizedStudies, 
  filteredKeys,
  supportLit, 
  updateCallback,
  masterSearchQuery, 
  // pageCallback,
  searchOccuredFlag,
  // currentPage,
  // setCurrentPage,
  studiesLoading,
  onDownload }) {

  const [forcedLoading, setForcedLoading] = useState(false);
  // const masterSearchQuery = useSelector(getSearchQuery);

  useEffect(() => {
    setForcedLoading(true);
    const timeOut = setTimeout(() => setForcedLoading(false), 300);
    return () => clearTimeout(timeOut);
  }, [masterSearchQuery]);

  if((forcedLoading && studiesLoading.newSearch) || (studiesLoading.loading)) return <LoadingSpinner />;

  const { fullCount, systematicCount, clinicalCount, observationalCount, caseCount, unknownCount } = summarizedStudies;

  return (
    <>
      <div className={styles.resultsSection} aria-label="results section">
        <ResultSectionHeaderMkII searchFlag={searchOccuredFlag} studyCounts={{ fullCount, systematicCount, clinicalCount, observationalCount, caseCount, unknownCount, limitedCount: summarizedStudies.results.length }} supportLitCount={supportLit.fullCount} onDownload={onDownload} />
        {/* {(summarizedStudies.results.length > 0 || supportLit.results.length > 0)
        && <Pagination
          masterSearchQuery={masterSearchQuery}
          callback={pageCallback}
          fullCount={summarizedStudies.fullCount + supportLit.fullCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage} />} */}
        {searchOccuredFlag && <section className={styles.sectionContainer} aria-label="search results">
          <SummarizedResults studies={summarizedStudies} 
            searchFlag={searchOccuredFlag} 
            filteredKeys={filteredKeys}
            updateCallback={updateCallback}
            // currentPage={currentPage}
          />
          {/* <section className={styles.sectionContainer}> */}
          <SupportLitResults supportLit={supportLit} 
            searchFlag={searchOccuredFlag} 
            // currentPage={currentPage}
          />
          {/* </section> */}
        </section>}
        <br/>
      </div>
    </>
  );
}

ResultsSection.propTypes = propTypes;
