import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const MarkdownContent = ({ content, onLinkClick }) => {
  // Custom components for ReactMarkdown
  const components = {
    // Override the default link component
    a: ({ node, children, ...props }) => (
      <a
        {...props}
        onClick={(e) => {
          // Pass through the click event to parent handler
          onLinkClick(e);
        }}
      >
        {children}
      </a>
    )
  };

  return (
    <ReactMarkdown 
      rehypePlugins={[rehypeRaw]}
      components={components}
    >
      {content}
    </ReactMarkdown>
  );
};

export default MarkdownContent;
