import React, { useEffect } from 'react';
import styles from './header.css';
import TopNavButton from './topNavButton/TopNavButton';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../../data/selectors/userAuthSelectors';
import PageNavButton from './pageNavButton/PageNavButton';
import { logoutUser, loadUser } from '../../data/actions/userAuthActions';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(loadUser());
  }, []);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const mediaQuery = window.matchMedia('(max-width: 425px)');
  const userOnMobile = mediaQuery.matches;

  let pageNav;
  if(!user.email) {
    pageNav = [
      <PageNavButton key={'Home'} text={'KNOW Home'} link={'/'} />,
      <PageNavButton key={'Methodology'} text={'Methodology'} link={'/methodology'} />,
      <PageNavButton key={'Studies'} text={'Studies'} link={'/login#studies'} />,
      <PageNavButton key={'Chat'} text={'AI Chat'} link={'/chat'} />,
      <PageNavButton key={'Provider Network'} text="Provider Network" link={'/providers'} />,
      <PageNavButton key={'Patient Resources'} text={'Patient Resources'} link={'/patient-resources'} />,
      <PageNavButton key={'About Us'} text={'About Us'} link={'/attributions'}/>
    ];
  } else {
    pageNav = [
      <PageNavButton key={'Home'} text={'KNOW Home'} link={'/'} />,
      <PageNavButton key={'Methodology'} text={userOnMobile ? 'Method-ology' : 'Methodology'} link={'/methodology'} />,
      <PageNavButton key={'Studies'} text={'Studies'} link={'/studies'} />,
      <PageNavButton key={'Chat'} text={'AI Chat'} link={'/chat'} />,
      <PageNavButton key={'Provider Network'} text="Provider Network" link={'/providers'} />,
      // <PageNavButton key={'Pearls'} text={'Clinical Pearls'} link={'/pearls'} />,
      <PageNavButton key={'Patient Resources'} text={'Patient Resources'} link={'/patient-resources'} />,
      <PageNavButton key={'Continuing Education'} text={'Continuing Education'} link={'/continuing-education'} />,
      <PageNavButton key='Attributions' text={'About Us'} link={'/attributions'} />
    ];

    // if(user.organizations !== 1 || user.role === 'allied') pageNav.splice(4, 1);
  }

  let topNav;
  if(!user.email) {
    topNav = [
      <TopNavButton key='donate' text={'Donate'} link="/donate" />,
      <TopNavButton key='join' text={'Sign Up'} link="/join-now" />,
      <TopNavButton key='login' text={'Log In'} link="/login" />
    ];
  } else {
    topNav = [
      <TopNavButton key='donate' text={'Donate'} link="/donate" />,
      // <TopNavButton key='addPearl' text={'Add Pearl'} link="/add-pearl" />,
      <TopNavButton key='logout' text={'Log Out'} link="/" handleButtonClick={handleLogout} />
    ];
    // if(user.role === 'student' || user.role === 'allied' || user.organizations !== 1) topNav.splice(1, 1);
    if(!userOnMobile && (user.role === 'org-admin' || user.role === 'know-admin')){
      topNav.unshift(
        <TopNavButton className={styles.noMobile} key='orgAdmin' text='Org Admin' link='/org-admin' />
      );
    }
    if(!userOnMobile && user.role === 'know-admin'){
      topNav.unshift(
        <TopNavButton className={styles.noMobile} key='knowAdmin' text='Know Admin' link='/know-admin' />
      );
    }
    if(user.email === 'ccnm@ccnm.org') {
      topNav.splice(-1);
    }
  }

  if(user.email && !user.agreed_to_terms && location.pathname != '/terms-of-use') navigate('/terms-of-use', { replace: true });
  
  return (
    
    <header>
      <div className={styles.nameTopNavContainer} role="header">
        <Link to="/" className={styles.titleContainer}>
          <div className="title" title="link to home" role='link'>
            <h1>KNOW</h1>
            <h4 className={styles.byline}>Knowledge in Integrative Oncology Website</h4>
          </div>
        </Link>
        {(userOnMobile && (user.role === 'org-admin' || user.role === 'know-admin')) && <p className={styles.mobileAdminWarning}>
          Admin pages are not available on mobile devices.
        </p>}
        <nav className={styles.topNav} aria-label="user navigation">
          {topNav}
        </nav>
      </div>

      <div id="logo"></div>

      { 
        pageNav.length === 7 ?
          (<nav className={`${styles.pageNav} ${styles.pageNavFull}`} aria-label="page navigation">
            {pageNav}
          </nav>)
          : 
          (<nav className={styles.pageNav} aria-label="page navigation">
            {pageNav}
          </nav>)
      }
    </header>
  );
};

export default Header;
