import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  IoIosHome, 
  IoIosCheckmark, 
  IoIosList, 
  IoIosText, 
  IoIosLock,
  IoIosContacts,
  IoIosPaper,
  IoIosMegaphone
} from 'react-icons/io';
import { HiOutlineSparkles } from 'react-icons/hi2';
import {
  FaRibbon
} from 'react-icons/fa';
import { getUser } from '../../../data/selectors/userAuthSelectors';
import { NavLink } from 'react-router-dom';
import styles from './pageNavButton.css';

export default function PageNavButton({ text, link }) {
  const user = useSelector(getUser);
  const iconKey = {
    'KNOW Home': <IoIosHome />,
    'Methodology': <IoIosCheckmark />,
    'Method-ology': <IoIosCheckmark />,
    'Studies': <IoIosList />,
    'AI Chat': <HiOutlineSparkles />,
    'Provider Network': <IoIosContacts />,
    'Clinical Pearls': <IoIosText />,
    'Patient Resources': <FaRibbon />,
    'Continuing Education': <IoIosPaper />,
    'About Us': <IoIosMegaphone />,
  };
  
  if(!user.email){
    iconKey.Studies = <IoIosLock />;
    iconKey['Continuing Education'] = <IoIosLock />;
  }

  const correctPageNavUserExperience = () => {
    return (<div>
      <NavLink
        to={link}
        className={({ isActive, isPending }) =>
          isPending ? 'pending' : isActive ? styles.navLink : ''
        }>
        {iconKey[text]}
        <span>{text}</span>
      </NavLink>
    </div>);
  };

  return (
    <>
      { correctPageNavUserExperience() }
    </>
  );
}

PageNavButton.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};
